import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/usr/src/app/apps/web/app/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/usr/src/app/apps/web/components/ui/feedback/toast/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts/fonts.ts\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"outfit\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts/fonts.ts\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"PlusJakarta\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts/fonts.ts\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"PlayfairDisplay\"}");
